import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Alert,
  Button,
  List,
  ListItem
} from "@mui/material";
import {
  AssignmentIndOutlined,
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  ForwardToInboxOutlined,
  MarkEmailReadOutlined,
  NoteAltOutlined
} from "@mui/icons-material";
import DialogTitle from "../../../shared/dialog/DialogTitle";
import { closeSignatureStatusDialog } from "../../../../business/actions/campaignDashboardActions";
import { getDigitalSignatureAgreementStatus } from "../../../../business/models/Plan/planActions";
import { getPlan } from "../../../../business/models/Plan/planSelectors";
import { Typography } from "../styles/planExportDialog";
import {
  AGREEMENT_ACTION_COMPLETED,
  AGREEMENT_ACTION_REQUESTED,
  AGREEMENT_CREATED,
  AGREEMENT_DOCUMENTS_VIEWED,
  AGREEMENT_EMAIL_VIEWED,
  AGREEMENT_WORKFLOW_COMPLETED
} from "../../../../business/constants/SignatureStatusDialogConstants";

const SignatureStatusDialog = () => {
  const dispatch = useDispatch();
  const currentPlan = useSelector(getPlan);
  const [agreementStatus, setAgreementStatus] = useState(undefined);
  const [apiMessage, setApiMessage] = useState("");
  const [apiIsSuccessful, setApiIsSuccessful] = useState(true);

  useEffect(() => {
    const getAgreementStatus = async () => {
      const { agreementStatusInfo, message, isSuccessful } = await dispatch(
        getDigitalSignatureAgreementStatus(
          currentPlan.digitalSignatureAgreementId
        )
      );
      setAgreementStatus(agreementStatusInfo);
      setApiMessage(message);
      setApiIsSuccessful(isSuccessful);
    };
    getAgreementStatus();
  }, [dispatch, currentPlan.digitalSignatureAgreementId]);

  const handleCancel = () => {
    dispatch(closeSignatureStatusDialog());
  };
  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle id="signature-status-dialog-title">
        <Typography component="span" variant="h6" color="secondary">
          Agreement/Document Signature Status
        </Typography>
      </DialogTitle>
      <DialogContent>
        {agreementStatus && agreementStatus.length < 1 && (
          <Grid item xs={12}>
            <Alert key={`alert-empty-records`} severity="warning">
              No records found
            </Alert>
          </Grid>
        )}
        {!apiIsSuccessful && (
          <Grid item xs={12}>
            <Alert key={`alert-api-error-message`} severity="error">
              {apiMessage}
            </Alert>
          </Grid>
        )}
        <Grid container>
          {agreementStatus &&
            agreementStatus.length > 0 &&
            agreementStatus.map((x, y) => {
              return (
                <Grid
                  key={`main-grid-item-${y}`}
                  item
                  alignContent="center"
                  xs={12}
                >
                  <Grid container>
                    <Grid xs={10} item alignContent="center">
                      <Typography variant="subtitle1" color="primary">
                        {`${x.name} (${x.identifier})`}
                      </Typography>
                    </Grid>
                    <Grid xs={2} item alignContent="center">
                      <Button
                        key={`download-button-${y}`}
                        type="outline"
                        color="primary"
                        disabled={!x.availableForDownload}
                      >
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  {x.statusInfo.map((status, index) => {
                    return (
                      <Grid container key={`child-grid-container-${index}`}>
                        <Grid item xs={12}>
                          <List>
                            <ListItem>
                              <Grid container>
                                <Grid xs={0.4} item>
                                  {(() => {
                                    switch (status.event) {
                                      case AGREEMENT_CREATED:
                                        return (
                                          <AssignmentOutlined></AssignmentOutlined>
                                        );
                                      case AGREEMENT_ACTION_REQUESTED:
                                        return (
                                          <ForwardToInboxOutlined></ForwardToInboxOutlined>
                                        );
                                      case AGREEMENT_EMAIL_VIEWED:
                                        return (
                                          <MarkEmailReadOutlined></MarkEmailReadOutlined>
                                        );
                                      case AGREEMENT_DOCUMENTS_VIEWED:
                                        return (
                                          <AssignmentIndOutlined></AssignmentIndOutlined>
                                        );
                                      case AGREEMENT_ACTION_COMPLETED:
                                        return (
                                          <NoteAltOutlined></NoteAltOutlined>
                                        );
                                      case AGREEMENT_WORKFLOW_COMPLETED:
                                        return (
                                          <AssignmentTurnedInOutlined></AssignmentTurnedInOutlined>
                                        );
                                      default:
                                        return (
                                          <AssignmentOutlined></AssignmentOutlined>
                                        );
                                    }
                                  })()}
                                </Grid>
                                <Grid xs={11.6} item>
                                  <Typography>
                                    {status.statusDescription}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Divider></Divider>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureStatusDialog;
