export const CHILD_CHECKBOX_LEFT_MARGIN = 3;

export const DISABLED_CHECKBOXES = [
  "Campaign Data",
  "Financials",
  "Remarks",
  "Overview of Changes",
  "Approval Client",
  "Footnotes- Custom"
];

export const HIDDEN_CHECKBOXES = ["Footnotes- Custom"];

export const SEPARATED_COMPONENTS = {
  "Target-Metrics-Distribution-KPI": [
    "Target-Metrics-Distribution-KPI- second table"
  ]
};

export const CHECKBOXES_WITH_TOP_BORDER = [
  "KPN Iprocurement",
  "Plan Summary",
  "Broadcasting",
  "Footnote- Buying after buying period"
];

export const THIRD_DIALOG_SECTION = [
  "Plan Summary",
  "Channel Summary",
  "Order Details"
];

export const AT_LEAST_ONE_MUST_BE_CHECKED =
  "At least one checkbox must be checked in this section *";

export const DIALOG_FAILED_TO_DISPLAY =
  "There was a problem loading the dialog.";

export const ORDER_DETAILS_ID = 110;

export const STAY_CHECKED_WHEN_BUDGET_FLEX =
  "* Do not uncheck when budget concerns Flex";

export const ORDER_DETAILS = "Order Details";
export const NEXT = "NEXT";
export const DOWNLOAD = "DOWNLOAD";
export const SUBMIT = "SUBMIT";
export const PIPE_SYMBOL = " | ";
export const SELECT_ORDERS = "Select Order(s)";
export const GO_EXPORT_COMPONENTS = "GO Export Components";
export const SEND_DOCUMENT_FOR_DIGITAL_SIGNATURES =
  "Send Document For Digital Signatures";
export const ERROR_MESSAGE_GROUP = "Group should be selected";
export const ERROR_MESSAGE_EMAIL_TEXT = "Email text cannot be empty ";
export const ERROR_MESSAGE_EMAIL_ADDRESS = "Email address is invalid";
export const ERROR_MESSAGE_DOCUMENT_NAME = "Document Name cannot be empty";
